import { Router } from '@/Components/Router'
import { cn } from '@/Lib'
import { FC, HTMLAttributes, ReactNode, useMemo } from 'react'

export interface UserBadge {
  badgeType: BadgeType
  achieved: boolean
}

export enum BadgeType {
  KICK_STARTER = 'kick_starter',
  GOAL_GETTER = 'goal_getter',
  SAVINGS_STARTER = 'savings_starter',
  SAVINGS_EXPERT = 'savings_expert',
  TFSA_TYCOON = 'tfsa_tycoon',
  TFSA_TITAN = 'tfsa_titan'
}

export enum BadgeColor {
  GREEN,
  LIME,
  PURPLE,
  ROSE,
  BLUE,
  ORANGE,
  YELLOW
}

type BadgeProps = Omit<HTMLAttributes<HTMLOrSVGElement>, 'color'> & {
  type: BadgeType
  achieved?: boolean
  active?: boolean
}

export const Badge: FC<BadgeProps> = ({
  achieved = false,
  type,
  active,
  className
}) => {
  const backgroundColor = useMemo(() => {
    if (!achieved) return 'text-slate-100 grayscale'

    switch (badgeContent[type].color) {
      case BadgeColor.BLUE:
        return 'text-blue-100'
      case BadgeColor.LIME:
        return 'text-lime-100'
      case BadgeColor.PURPLE:
        return 'text-purple-100'
      case BadgeColor.ROSE:
        return 'text-rose-100'
      case BadgeColor.GREEN:
        return 'text-green-100'
      case BadgeColor.YELLOW:
        return 'text-yellow-100'
      case BadgeColor.ORANGE:
        return 'text-orange-100'
      default:
        return 'text-slate-100'
    }
  }, [achieved, type])

  const strokeColor = useMemo(() => {
    if (!achieved) return 'group-hover:stroke-slate-300'

    switch (badgeContent[type].color) {
      case BadgeColor.BLUE:
        return 'group-hover:stroke-blue-300'
      case BadgeColor.LIME:
        return 'group-hover:stroke-lime-300'
      case BadgeColor.PURPLE:
        return 'group-hover:stroke-purple-300'
      case BadgeColor.ROSE:
        return 'group-hover:stroke-rose-300'
      case BadgeColor.GREEN:
        return 'group-hover:stroke-green-300'
      case BadgeColor.YELLOW:
        return 'group-hover:stroke-yellow-300'
      case BadgeColor.ORANGE:
        return 'group-hover:stroke-orange-300'
      default:
        return 'group-hover:stroke-slate-300'
    }
  }, [achieved, type])

  return (
    <svg
      width='80'
      height='80'
      viewBox='0 0 80 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('group flex-none', backgroundColor, className)}
    >
      <path
        d='M34 3.4641C37.7128 1.32051 42.2872 1.32051 46 3.4641L68.641 16.5359C72.3538 18.6795 74.641 22.641 74.641 26.9282V53.0718C74.641 57.359 72.3538 61.3205 68.641 63.4641L46 76.5359C42.2872 78.6795 37.7128 78.6795 34 76.5359L11.359 63.4641C7.64617 61.3205 5.35898 57.359 5.35898 53.0718V26.9282C5.35898 22.641 7.64617 18.6795 11.359 16.5359L34 3.4641Z'
        className='fill-current'
      />
      {active && (
        <path
          d='M34 3.4641C37.7128 1.32051 42.2872 1.32051 46 3.4641L68.641 16.5359C72.3538 18.6795 74.641 22.641 74.641 26.9282V53.0718C74.641 57.359 72.3538 61.3205 68.641 63.4641L46 76.5359C42.2872 78.6795 37.7128 78.6795 34 76.5359L11.359 63.4641C7.64617 61.3205 5.35898 57.359 5.35898 53.0718V26.9282C5.35898 22.641 7.64617 18.6795 11.359 16.5359L34 3.4641Z'
          className={cn('fill-current group-hover:stroke-2', strokeColor)}
        />
      )}
      {badgeContent[type].svg}
    </svg>
  )
}

type BadgeContent = {
  [Property in BadgeType]: {
    achieved: ReactNode
    disabled: ReactNode
    svg: ReactNode
    color: BadgeColor
  }
}

export const badgeContent: BadgeContent = {
  kick_starter: {
    achieved: (
      <div className='flex w-full flex-col gap-2'>
        <p className='font-medium text-strong'>Kick starter</p>
        <p className='text-medium'>
          Wear it like a badge of honor. You&apos;ve started, now don&apos;t
          stop!
        </p>
      </div>
    ),
    disabled: (
      <div className='flex w-full flex-col gap-2'>
        <p className='font-medium text-strong'>Kick starter</p>
        <p className='text-medium'>
          Wear it like a badge of honor. You&apos;ve started, now don&apos;t
          stop!
        </p>
      </div>
    ),
    svg: (
      <>
        <path
          d='M40 21C40 31.4934 31.4934 40 21 40V40L21 21L40 21V21Z'
          fill='#16A34A'
        />
        <path
          d='M21 59C21 48.5066 29.5066 40 40 40V40L40 59L21 59V59Z'
          fill='#16A34A'
        />
        <path
          d='M40 21C50.4934 21 59 29.5066 59 40V40C59 50.4934 50.4934 59 40 59V59L40 21V21Z'
          fill='#4ADE80'
        />
      </>
    ),
    color: BadgeColor.GREEN
  },
  goal_getter: {
    achieved: (
      <div className='flex w-full flex-col gap-2'>
        <p className='font-medium text-strong'>Goal Getter</p>
        <p className='text-medium'>
          {' '}
          You’ve earned this badge for setting and reaching a goal.
        </p>
      </div>
    ),
    disabled: (
      <div className='flex w-full flex-col gap-2'>
        <p className='font-medium text-strong'>Goal Getter</p>
        <p className='text-medium'>
          Earn this badge by setting and reaching a goal.
        </p>
        {/*<Router href='/learn' className='text-primary'>*/}
        {/*  Go there now*/}
        {/*</Router>*/}
      </div>
    ),
    svg: (
      <>
        <path
          d='M21.0003 21C31.4935 21 40 29.5065 40 39.9997V39.9997V39.9997C29.5067 39.9997 21.0003 31.4933 21.0003 21V21V21Z'
          fill='#9333EA'
        />
        <path
          d='M39.9997 58.9995C29.5065 58.9995 21 50.493 21 39.9998V39.9998V39.9998C31.4933 39.9998 39.9997 48.5063 39.9997 58.9995V58.9995V58.9995Z'
          fill='#9333EA'
        />
        <path
          d='M59 40V40C59 50.4933 50.4935 58.9997 40.0003 58.9997V58.9997V58.9997C40.0003 48.5065 48.5067 40 59 40V40Z'
          fill='#C084FC'
        />
        <path
          d='M40 40V40C40 29.5067 48.5065 21.0003 58.9997 21.0003V21.0003V21.0003C58.9997 31.4935 50.4933 40 40 40V40Z'
          fill='#C084FC'
        />
      </>
    ),
    color: BadgeColor.PURPLE
  },
  savings_starter: {
    achieved: (
      <div className='flex w-full flex-col gap-2'>
        <p className='font-medium text-strong'>Savings Starter</p>
        <p className='text-medium'>
          You&apos;ve earned this badge for getting started with your emergency
          savings.
        </p>
      </div>
    ),
    disabled: (
      <div className='flex w-full flex-col gap-2'>
        <p className='font-medium text-strong'>Savings Starter</p>
        <p className='text-medium'>
          Earn this badge by starting your emergency savings.
        </p>
        <Router href='/emergency-savings' className='text-primary'>
          Go there now
        </Router>
      </div>
    ),
    svg: (
      <>
        <path
          d='M21 21V21C31.4934 21 39.9999 29.5066 39.9999 39.9999V59V59C29.5066 59 21 50.4935 21 40.0001L21 21Z'
          fill='#2563EB'
        />
        <path
          d='M40 39.9999C40 29.5066 48.5066 21 58.9999 21V21V40.0001C58.9999 50.4935 50.4934 59 40 59V59L40 39.9999Z'
          fill='#60A5FA'
        />
      </>
    ),
    color: BadgeColor.BLUE
  },
  savings_expert: {
    achieved: (
      <div className='flex w-full flex-col gap-2'>
        <p className='font-medium text-strong'>Emergency Savings Expert</p>
        <p className='text-medium'>
          You&apos;ve earned this badge for reaching your emergency savings
          goal.
        </p>
      </div>
    ),
    disabled: (
      <div className='flex w-full flex-col gap-2'>
        <p className='font-medium text-strong'>Emergency Savings Expert</p>
        <p className='text-medium'>
          Earn this badge by reaching your emergency savings goal.
        </p>
        <Router href='/emergency-savings' className='text-primary'>
          Go there now
        </Router>
      </div>
    ),
    svg: (
      <>
        <path
          d='M21 40.0001C21 29.5067 29.5066 21.0001 40 21.0001C40 31.4935 31.4934 40.0001 21 40.0001Z'
          fill='#EA580C'
        />
        <path
          d='M21 40.0001C31.4933 40.0001 39.9997 48.5066 39.9997 58.9998C29.5065 58.9998 21 50.4934 21 40.0001Z'
          fill='#EA580C'
        />
        <path
          d='M40 30.5001C40 25.2534 44.2533 21.0001 49.5 21.0001L59 21.0001V30.5001C59 35.7468 54.7467 40.0001 49.5 40.0001C44.2533 40.0001 40 35.7468 40 30.5001Z'
          fill='#FB923C'
        />
        <path
          d='M40 58.9998C40 48.5066 48.5065 40.0001 58.9997 40.0001C58.9997 50.4934 50.4933 58.9998 40 58.9998Z'
          fill='#FB923C'
        />
      </>
    ),
    color: BadgeColor.ORANGE
  },
  tfsa_tycoon: {
    achieved: (
      <div className='flex w-full flex-col gap-2'>
        <p className='font-medium text-strong'>Tax-Free Tycoon</p>
        <p className='text-medium'>
          You&apos;ve earned this badge for setting up your Tax-free savings
          account.
        </p>
      </div>
    ),
    disabled: (
      <div className='flex w-full flex-col gap-2'>
        <p className='font-medium text-strong'>Tax-Free Tycoon</p>
        <p className='text-medium'>
          Earn this badge by setting up your Tax-free savings account.
        </p>
        <Router href='/tfsa' className='text-primary'>
          Go there now
        </Router>
      </div>
    ),
    svg: (
      <>
        <path
          d='M59.0011 39.9999L59.0011 49.4998C59.0011 54.7464 54.7479 58.9997 49.5012 58.9997C44.2546 58.9997 40.0014 54.7464 40.0014 49.4998L40.0014 39.9999L59.0011 39.9999Z'
          fill='#FACC15'
        />
        <path
          d='M39.9989 40L39.9989 30.5001C39.9989 25.2535 44.2521 21.0003 49.4988 21.0003C54.7454 21.0003 58.9986 25.2535 58.9986 30.5001L58.9986 40L39.9989 40Z'
          fill='#FACC15'
        />
        <path
          d='M20.9992 30.5001C20.9992 25.2535 25.2524 21.0002 30.4991 21.0002C35.7457 21.0002 39.9989 25.2535 39.9989 30.5001C39.9989 35.7467 35.7457 40 30.4991 40C25.2524 40 20.9992 35.7467 20.9992 30.5001Z'
          fill='#CA8A04'
        />
        <path
          d='M20.9987 58.9998C20.9987 48.5065 29.5051 40.0001 39.9984 40.0001C39.9984 50.4933 31.4919 58.9998 20.9987 58.9998Z'
          fill='#CA8A04'
        />
      </>
    ),
    color: BadgeColor.YELLOW
  },
  tfsa_titan: {
    achieved: (
      <div className='flex w-full flex-col gap-2'>
        <p className='font-medium text-strong'>Tax-Free Titan</p>
        <p className='text-medium'>
          You&apos;ve earned this badge for maxing out your tax-free savings
          allowance for the current financial year.
        </p>
      </div>
    ),
    disabled: (
      <div className='flex w-full flex-col gap-2'>
        <p className='font-medium text-strong'>Tax-Free Titan</p>
        <p className='text-medium'>
          Earn this badge by maxing out your tax-free savings allowance for the
          current financial year.
        </p>
        <Router href='/tfsa' className='text-primary'>
          Go there now
        </Router>
      </div>
    ),
    svg: (
      <>
        <path
          d='M20.9994 30.5C20.9994 25.2534 25.2526 21.0001 30.4993 21.0001L39.9991 21.0001L39.9991 30.5C39.9991 35.7466 35.7459 39.9998 30.4993 39.9998C25.2526 39.9998 20.9994 35.7466 20.9994 30.5Z'
          fill='#65A30D'
        />
        <path
          d='M20.9989 49.5C20.9989 44.2534 25.2522 40.0001 30.4988 40.0001H39.9986V49.5C39.9986 54.7466 35.7454 58.9998 30.4988 58.9998C25.2522 58.9998 20.9989 54.7466 20.9989 49.5Z'
          fill='#65A30D'
        />
        <path
          d='M59.0011 40C59.0011 50.4933 50.4946 58.9997 40.0014 58.9997C40.0014 48.5065 48.5078 40 59.0011 40Z'
          fill='#A3E635'
        />
        <path
          d='M39.9991 40C39.9991 29.5067 48.5056 21.0003 58.9989 21.0003C58.9989 31.4935 50.4924 40 39.9991 40Z'
          fill='#A3E635'
        />
      </>
    ),
    color: BadgeColor.LIME
  }
}
